import { DocumentNode, gql } from 'apollo-boost';

export const LOGIN = gql`
  mutation login($document: String!, $password: String!, $type: String!) {
    login(document: $document, password: $password, type: $type) {
      accessToken
      refreshToken
    }
  }
`;

export const LOCAL_LOGIN: DocumentNode = gql`
  mutation localLogin($accessToken: String!, $refreshToken: String!) {
    localLogin(accessToken: $accessToken, refreshToken: $refreshToken) @client
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($changePasswordInput: ChangePasswordInput!) {
    changePassword(changePasswordInput: $changePasswordInput)
  }
`;

export const LOCAL_LOGOUT: DocumentNode = gql`
  mutation localLogout {
    localLogout @client
  }
`;

export const LOCAL_SET_AMOUNT: DocumentNode = gql`
  mutation localSetAmount($amount: Number!) {
    localSetAmount(amount: $amount) @client
  }
`;

export const LOCAL_SET_TRANSACTION_ID: DocumentNode = gql`
  mutation localSetTransactionId($transactionId: String!) {
    localSetTransactionId(transactionId: $transactionId) @client
  }
`;

export const SET_SIDEBAR: DocumentNode = gql`
  mutation setSidebar($open: Boolean!) {
    setSidebar(open: $open) @client
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput)
  }
`;

export const FORGOT_PASSWORD_CHANGE = gql`
  mutation ForgotPasswordChange($restorePasswordInput: RestorePasswordInput!) {
    forgotPasswordChange(restorePasswordInput: $restorePasswordInput)
  }
`;

export const TRANSACTION_REQUEST = gql`
  mutation transactionRequest($amount: Float!) {
    transactionRequest(amount: $amount)
  }
`;

export const REFUND_TRANSACTION = gql`
  mutation refundTransaction($refundTransactionInput: RefundTransactionInput!) {
    refundTransaction(refundTransactionInput: $refundTransactionInput) {
      id
      createdAt
      amount
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshAccessToken($token: String!) {
    refreshAccessToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;

export const SIGNUP_PARTNER = gql`
  mutation signupPartner($signupInput: SignupInput!, $partnerInput: PartnerInput!) {
    signupPartner(signupInput: $signupInput, partnerInput: $partnerInput)
  }
`;

export const SIGNUP_VALIDATOR = gql`
  mutation signupValidator($signupInput: SignupInput!) {
    signupValidator(signupInput: $signupInput) {
      document
      email
      phone
    }
  }
`;
