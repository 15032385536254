import { gql } from 'apollo-boost';

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const LOCAL_GET_AMOUNT = gql`
  query localGetAmount {
    localGetAmount @client
  }
`;

export const LOCAL_GET_TRANSACTION_ID = gql`
  query localGetTransactionId {
    localGetTransactionId @client
  }
`;

export const IS_SIDEBAR_OPEN = gql`
  query isSidebarOpen {
    isSidebarOpen @client
  }
`;

export const GET_TRANSACTIONS = gql`
  query transactions($from: Date, $to: Date, $type: String!) {
    transactions(from: $from, to: $to, type: $type) {
      id
      createdAt
      relatedWith
      amount
      farmer {
        firstName
        lastName
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query transaction($id: String!) {
    transaction(id: $id) {
      id
      createdAt
      amount
    }
  }
`;

export const GET_ME = gql`
  query me {
    me {
      id
      createdAt
      updatedAt
      email
      phone
      document
      address {
        id
        street
        streetNumber
        additionalInfos
        city
        state
        zipCode
      }
      shouldChangePassword

      farmer {
        id
      }
      partner {
        id
        name
        administrator
      }
      guarantor {
        id
      }
    }
  }
`;
export const CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;
