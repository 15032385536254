export default {
  LOGIN: '/login',
  HOME: '/',
  TRANSACTION: '/transaction',
  TRANSACTION_QR_CODE: '/transaction/read-qrcode',
  TRANSACTION_CONFIRM: '/transaction/confirm-transaction',
  EXTRACT: '/extract',
  HELP: '/settings/central',
  CHANGE_PASSWORD: '/settings/change-password',
  SETTINGS: '/settings',
  RESET_PASSWORD: '/reset-password/[key]',
  SIGNUP: '/signup',
};
